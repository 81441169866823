import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { LocalStorageService } from '../modules/shared/services/local-storage.service';
import { Observable } from 'rxjs';
import saveAs from 'file-saver';
import { Stage } from '../modules/shared/domain/enums/stage';

@Injectable({
  providedIn: 'root',
})
export class ExtendedReportingService {
  URL: string = this.localStorageService.apiUrl;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {}

  getDeviceUpdateStatus(): Observable<any> {
    // Define the endpoint URL to get data
    const endpoint = `${this.URL}/ExtendedReporting/GetDeviceApplications`;

    // Make the GET request and return the response
    return this.http.get<DeviceUpdateStatus>(endpoint).pipe(
      map((response) => {
        // Optionally process the response if needed
        return response;
      })
    );
  }

  getDeviceUpdateStatusAsCsv(): void {
    const endpoint = `${this.URL}/ExtendedReporting/GetDeviceApplications/csv`;
    this.http.get(endpoint, { responseType: 'blob' }).subscribe(
      (blob: Blob) => {
        saveAs(blob, `device_update_status-${new Date().toISOString()}.csv`);
      },
      (error) => {
        console.error('Error downloading CSV file:', error);
      }
    );
  }

  getApplicationUpdateStatus(): Observable<any> {
    // Define the endpoint URL to get data
    const endpoint = `${this.URL}/ExtendedReporting/GetApplicationUpdateStatus`;

    // Make the GET request and return the response
    return this.http.get<ApplicationUpdateStatus>(endpoint).pipe(
      map((response) => {
        // Optionally process the response if needed
        return response;
      })
    );
  }

  getApplicationUpdateStatusAsCsv(): void {
    const endpoint = `${this.URL}/ExtendedReporting/GetApplicationUpdateStatus/csv`;
    this.http.get(endpoint, { responseType: 'blob' }).subscribe(
      (blob: Blob) => {
        saveAs(blob, `app_update_status-${new Date().toISOString()}.csv`);
      },
      (error) => {
        console.error('Error downloading CSV file:', error);
      }
    );
  }

  getApplicationLifecycleStatusAsCsv(): void {
    const endpoint = `${this.URL}/ExtendedReporting/GetApplicationLifecycleStatus/csv`;
    this.http.get(endpoint, { responseType: 'blob' }).subscribe(
      (blob: Blob) => {
        saveAs(blob, `app_lifecycle_status-${new Date().toISOString()}.csv`);
      },
      (error) => {
        console.error('Error downloading CSV file:', error);
      }
    );
  }

  getMspApplicationUpdateStatus(): Observable<any> {
    const endpoint = `${this.URL}/ExtendedReporting/GetApplicationUpdateStatusForMsp`;

    return this.http.get<ApplicationUpdateStatus>(endpoint).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getMspApplicationUpdateStatusAsCsv(): void {
    const endpoint = `${this.URL}/ExtendedReporting/GetApplicationUpdateStatusForMsp/csv`;

    this.http.get(endpoint, { responseType: 'blob' }).subscribe(
      (blob: Blob) => {
        saveAs(blob, `app_update_status-${new Date().toISOString()}.csv`);
      },
      (error) => {
        console.error('Error downloading CSV file:', error);
      }
    );
  }

  getMspDeviceUpdateStatus(): Observable<any> {
    const endpoint = `${this.URL}/ExtendedReporting/GetDeviceUpdateComplianceMsp`;

    return this.http.get<DeviceUpdateStatus>(endpoint).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getMspDeviceUpdateStatusAsCsv(): void {
    const endpoint = `${this.URL}/ExtendedReporting/GetDeviceUpdateComplianceMsp/csv`;

    this.http.get(endpoint, { responseType: 'blob' }).subscribe(
      (blob: Blob) => {
        saveAs(blob, `device-status-${new Date().toISOString()}.csv`);
      },
      (error) => {
        console.error('Error downloading CSV file:', error);
      }
    );
  }

  getMspVersionLifecycleStatus(): Observable<any> {
    const endpoint = `${this.URL}/ExtendedReporting/GetApplicationVersionTrackingMsp`;

    return this.http.get<ApplicationVersionTracking>(endpoint).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getMspVersionLifecycleStatusAsCsv(): void {
    const endpoint = `${this.URL}/ExtendedReporting/GetApplicationVersionTrackingMsp/csv`;

    this.http.get(endpoint, { responseType: 'blob' }).subscribe(
      (blob: Blob) => {
        saveAs(blob, `version-lifecycle-${new Date().toISOString()}.csv`);
      },
      (error) => {
        console.error('Error downloading CSV file:', error);
      }
    );
  }
}

export interface DeviceUpdateStatus {
  deviceName: string;
  customer: string;
  installedApplications: number;
  updatedApplications: number;
  compliance: number;
}

export interface ApplicationUpdateStatus {
  applicationName: string;
  customer: string;
  installedDevices: number;
  updatedDevices: number;
  compliance: number;
}

export interface ApplicationVersionTracking {
  id: number;
  application: string;
  customer: string;
  version: string;
  lifecycle: Stage;
  totalVersions: number;
  testPassed: number;
  acceptancePassed: number;
  productionPassed: number;
}
