import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AssignmentDetailsComponent } from './components/assignment-details/assignment-details.component';
import { AssignmentsComponent } from './components/assignments/assignments.component';
import { BlueprintDetailsComponent } from './components/blueprint-details/blueprint-details.component';
import { BlueprintsComponent } from './components/blueprints/blueprints.component';
import { ActionsComponent } from './components/configuration/actions/actions.component';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { EndpointsComponent } from './components/configuration/endpoints/endpoints.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { StatusComponent } from './components/status/status.component';
import { LoginComponent } from './components/login/login.component';
import { GroupsComponent } from './components/configuration/groups/groups.component';
import { TagsComponent } from './components/tags/tags.component';
import { ApplicationsComponent } from './components/reports/applications/applications.component';
import { PackageLifecycleComponent } from './components/package-lifecycle/package-lifecycle.component';
import { PackageBuilderComponent } from './modules/package-builder/components/package-builder/package-builder.component';
import { DashboardComponent as MspDashboardComponent } from './modules/msp/dashboard/components/dashboard/dashboard.component';
import { PackagesPageComponent as MspPackagesPageComponent } from './modules/msp/packages-page/packages-page/packages-page.component';
import { MspGuard } from './guards/msp.guard';
import { PackageSubscriptionPageComponent } from './modules/package-subscription/package-subscription-page/package-subscription-page.component';
import { CustomersComponent as MspCustomersPageComponent } from './modules/msp/customers/components/customers/customers.component';
import { environment } from 'src/environments/environment';
import { CustomerBuilderComponent } from './modules/msp/customer-builder/components/customer-builder/customer-builder.component';
import { AuthGuard } from './guards/auth.guard';
import { GroupAssignmentDetailsComponent } from './components/group-assignment-details/group-assignment-details.component';
import { RecycleBinComponent } from './modules/package-cleanup/components/recycle-bin/recycle-bin.component';
import { ConfigureComponent } from './modules/package-cleanup/components/configure/configure.component';
import { ConfigurePackageLifecycleComponent } from './components/configure-package-lifecycle/configure-package-lifecycle.component';
import { VersionHistoryPageComponent } from './components/version-history-page/version-history-page.component';
import { MspVersionHistoryPageComponent } from './components/msp-version-history-page/msp-version-history-page.component';
import { PackageSuggestionsComponent } from './components/package-suggestions/package-suggestions.component';
import { ApplicationsUpdateStatusComponent } from './components/reports/applications-update-status/applications-update-status.component';
import { DeviceUpdateStatusComponent } from './components/reports/device-update-status/device-update-status.component';
import { ApplicationLifecycleTrackingComponent } from './components/reports/application-lifecycle-tracking/application-lifecycle-tracking.component';
import { MspApplicationsUpdateStatusComponent } from './components/reports-msp/msp-applications-update-status/msp-applications-update-status.component';
import { MspDeviceUpdateComplianceComponent } from './components/reports-msp/msp-device-update-compliance/msp-device-update-compliance.component';
import { MspVersionTrackingComponent } from './components/reports-msp/msp-version-tracking/msp-version-tracking.component';


const routes: Routes = [
  { path: 'msp/dashboard',                             component: MspDashboardComponent,                 canActivate: [AuthGuard, MspGuard] },
  { path: 'msp/packages',                              component: MspPackagesPageComponent,              canActivate: [AuthGuard, MspGuard] },
  { path: 'msp/packages/lifecycle',                    component: PackageLifecycleComponent,             canActivate: [AuthGuard, MspGuard] },
  { path: 'msp/packages/configure-lifecycle',          component: ConfigurePackageLifecycleComponent,    canActivate: [AuthGuard, MspGuard] },
  { path: 'msp/customers',                             component: MspCustomersPageComponent,             canActivate: [AuthGuard, MspGuard] },
  { path: 'msp/customer-builder',                      component: CustomerBuilderComponent,              canActivate: [AuthGuard, MspGuard] },
  { path: 'msp/package-builder',                       component: PackageBuilderComponent,               canActivate: [AuthGuard, MspGuard] },
  { path: 'msp/recycle-bin',                           component: RecycleBinComponent,                   canActivate: [AuthGuard, MspGuard] },
  { path: 'msp/recycle-bin/configure',                 component: ConfigureComponent,                    canActivate: [AuthGuard, MspGuard] },
  { path: 'msp/version-history',                       component: MspVersionHistoryPageComponent,        canActivate: [AuthGuard, MspGuard] },
  { path: 'msp/application-update-status',             component: MspApplicationsUpdateStatusComponent,  canActivate: [AuthGuard, MspGuard] },
  { path: 'msp/device-update-compliance',              component: MspDeviceUpdateComplianceComponent,    canActivate: [AuthGuard, MspGuard] },
  { path: 'msp/version-tracking',                      component: MspVersionTrackingComponent,           canActivate: [AuthGuard, MspGuard] },
  { path: 'configuration',                             component: ConfigurationComponent,                canActivate: [AuthGuard] },
  { path: 'configuration/endpoints',                   component: EndpointsComponent,                    canActivate: [AuthGuard] },
  { path: 'configuration/actions',                     component: ActionsComponent,                      canActivate: [AuthGuard] },
  { path: 'configuration/actions/configure-lifecycle', component: ConfigurePackageLifecycleComponent,    canActivate: [AuthGuard] },
  { path: 'configuration/actions/lifecycle',           component: PackageLifecycleComponent,             canActivate: [AuthGuard] },
  { path: 'configuration/groups',                      component: GroupsComponent,                       canActivate: [AuthGuard] },
  { path: 'reports/applications',                      component: ApplicationsComponent,                 canActivate: [AuthGuard] },
  { path: 'reports/application-update-status',         component: ApplicationsUpdateStatusComponent,     canActivate: [AuthGuard] },
  { path: 'reports/device-update-status',              component: DeviceUpdateStatusComponent,           canActivate: [AuthGuard] },
  { path: 'reports/application-lifecycle-tracking',    component: ApplicationLifecycleTrackingComponent, canActivate: [AuthGuard] },
  { path: 'status/:endpointId',                        component: StatusComponent,                       canActivate: [AuthGuard] },
  { path: 'assignments',                               component: AssignmentsComponent,                  canActivate: [AuthGuard] },
  { path: 'blueprints',                                component: BlueprintsComponent,                   canActivate: [AuthGuard] },
  { path: 'blueprintdetails/:blueprintId',             component: BlueprintDetailsComponent,             canActivate: [AuthGuard] },
  { path: 'assignmentdetails/:assignmentId',           component: AssignmentDetailsComponent,            canActivate: [AuthGuard] },
  { path: 'groupdetails/:groupId',                     component: GroupAssignmentDetailsComponent,       canActivate: [AuthGuard] },
  { path: 'dashboard',                                 component: DashboardComponent,                    canActivate: [AuthGuard] },
  { path: 'tags',                                      component: TagsComponent,                         canActivate: [AuthGuard] },
  { path: 'package-builder',                           component: PackageBuilderComponent,               canActivate: [AuthGuard] },
  { path: 'gallery',                                   component: PackageSubscriptionPageComponent,      canActivate: [AuthGuard] },
  { path: 'recycle-bin',                               component: RecycleBinComponent,                   canActivate: [AuthGuard] },
  { path: 'recycle-bin/configure',                     component: ConfigureComponent,                    canActivate: [AuthGuard] },
  { path: 'version-history',                           component: VersionHistoryPageComponent,           canActivate: [AuthGuard] },
  { path: 'suggested-packages',                        component: PackageSuggestionsComponent,           canActivate: [AuthGuard] },
  { path: 'login',                                     component: LoginComponent },
  { path: '**', pathMatch: 'full', redirectTo: environment.isMsp ? 'msp/dashboard' : 'dashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
