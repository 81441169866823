import { Component, OnInit } from '@angular/core';
import { ApplicationUpdateStatus, ExtendedReportingService } from 'src/app/services/extended-reporting.service';

@Component({
  selector: 'app-msp-applications-update-status',
  templateUrl: './msp-applications-update-status.component.html',
  styleUrls: ['./msp-applications-update-status.component.scss']
})
export class MspApplicationsUpdateStatusComponent implements OnInit {
  deviceStatuses: ApplicationUpdateStatus[] = [];
  isLoading = true;

  constructor(private extendedReportingService: ExtendedReportingService) {}

  ngOnInit(): void {
    this.extendedReportingService.getMspApplicationUpdateStatus().subscribe(
      (data) => {
        this.isLoading = false;
        this.deviceStatuses = data;
        console.log('Fetched Aplication Statuses: ', this.deviceStatuses);
      },
      (error) => {
        console.error('Error fetching Aplication statuses: ', error);
      }
    );
  }

  downloadCsv(): void {
    this.extendedReportingService.getMspApplicationUpdateStatusAsCsv();
  }
}
