import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '../modules/shared/services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class CpeServiceService {
  URL: string = this.localStorageService.apiUrl;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {}

  getCpeInformation() {
    return this.http.get<CpeInformation[]>(`${this.URL}/cpeInfo`);
  }

  patchCpeString(id: number, cpeString: string) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.patch(
      `${this.URL}/cpeInfo/${id}/cpe-string`,
      JSON.stringify(cpeString),
      { headers }
    );
  }
}

export interface CpeInformation {
  id: number;
  displayName: string;
  cpeString: string;
  vendor: string;
  isVerified: boolean;
}
