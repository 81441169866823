import { Component, OnInit } from '@angular/core';
import { DeviceUpdateStatus, ExtendedReportingService } from 'src/app/services/extended-reporting.service';

@Component({
  selector: 'app-msp-device-update-compliance',
  templateUrl: './msp-device-update-compliance.component.html',
  styleUrls: ['./msp-device-update-compliance.component.scss'],
})
export class MspDeviceUpdateComplianceComponent implements OnInit {
  deviceStatuses: DeviceUpdateStatus[] = [];
  isLoading = true;

  constructor(private extendedReportingService: ExtendedReportingService) {}

  ngOnInit(): void {
    // Fetch device update statuses on component init
    this.extendedReportingService.getMspDeviceUpdateStatus().subscribe(
      (data) => {
        this.isLoading = false;
        this.deviceStatuses = data;
        console.log('Fetched Device Statuses: ', this.deviceStatuses);
      },
      (error) => {
        console.error('Error fetching device statuses: ', error);
      }
    );
  }

  downloadCsv(): void {
    this.extendedReportingService.getMspDeviceUpdateStatusAsCsv();
  }
}
