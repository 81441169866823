import { DragDropModule } from '@angular/cdk/drag-drop';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragToSelectModule } from 'ngx-drag-to-select';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { BrowserModule } from '@angular/platform-browser';
import {
  MsalModule,
  MsalInterceptor,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { CdsModule } from '@cds/angular';
import {
  ClarityIcons,
  successStandardIcon,
  timesCircleIcon,
  infoStandardIcon,
  windowCloseIcon,
  timesIcon,
  undoIcon,
  installIcon,
  uninstallIcon,
  downloadIcon,
  trashIcon,
  errorStandardIcon,
  errorMiniIcon,
  angleIcon,
  cogIcon,
  wrenchIcon,
  refreshIcon,
  floppyIcon,
  redoIcon,
  sunIcon,
  moonIcon,
  clusterIcon,
  fileSettingsIcon,
  circleIcon,
  banIcon,
  lockIcon,
  unlockIcon,
  dragHandleIcon,
  bubbleExclamationIcon,
  noteIcon,
  listIcon,
  exportIcon,
  warningStandardIcon,
  plusCircleIcon,
  uploadIcon,
  folderIcon,
  copyIcon,
  popOutIcon,
  switchIcon,
  gridChartIcon,
  usersIcon,
  applicationIcon,
  computerIcon,
  lineChartIcon,
  applicationsIcon,
  devicesIcon,
  pencilIcon,
  worldIcon,
  minusCircleIcon,
  recycleIcon,
  backupRestoreIcon,
  processOnVmIcon,
  flagIcon,
  historyIcon,
  asteriskIcon,
} from '@cds/core/icon';
import { ClarityModule } from '@clr/angular';
import { ContextMenuModule } from 'ngx-contextmenu';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AssignmentDetailsComponent } from './components/assignment-details/assignment-details.component';
import { AssignmentsComponent } from './components/assignments/assignments.component';
import { BlueprintDetailsComponent } from './components/blueprint-details/blueprint-details.component';
import { BlueprintsComponent } from './components/blueprints/blueprints.component';
import { ActionsComponent } from './components/configuration/actions/actions.component';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { CronEditComponent } from './components/configuration/cron-edit/cron-edit.component';
import { EndpointsComponent } from './components/configuration/endpoints/endpoints.component';
import { GroupsComponent } from './components/configuration/groups/groups.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DownloadMsiInstallerComponent } from './components/download-msi-installer/download-msi-installer.component';
import { EndpointLogsComponent } from './components/endpoint-logs/endpoint-logs.component';
import { ExpandablePackageItemComponent } from './components/expandable-package-item/expandable-package-item.component';
import { LoginComponent } from './components/login/login.component';
import { NotifyMeComponent } from 'src/app/components/notify-me/notify-me.component';
import { PackageLifecycleColumnComponent } from './components/package-lifecycle-column/package-lifecycle-column.component';
import { PackageLifecycleComponent } from './components/package-lifecycle/package-lifecycle.component';
import { PackageVersionCardComponent } from './components/package-version-card/package-version-card.component';
import { ApplicationsComponent } from './components/reports/applications/applications.component';
import { GroupBadgeComponent } from './components/shared/group-badge/group-badge.component';
import { LifecycleBadgeComponent } from './components/shared/lifecycle-badge/lifecycle-badge.component';
import { NdAddTagsModalComponent } from './components/shared/nd-add-tags-modal/nd-add-tags-modal.component';
import { SchedulePickingTabComponent } from './components/shared/schedule-picking-tab/schedule-picking-tab.component';
import { TabbedTerminalComponent } from './components/shared/tabbed-terminal/tabbed-terminal.component';
import { TerminalComponent } from './components/shared/terminal/terminal.component';
import { StatusComponent } from './components/status/status.component';
import { TagsComponent } from './components/tags/tags.component';
import { AuthenticationInterceptor } from './interceptors/authentication.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { CustomerBuilderModule } from './modules/msp/customer-builder/customer-builder.module';
import { CustomersModule } from './modules/msp/customers/customers.module';
import { MspDashboardModule } from './modules/msp/dashboard/msp-dashboard.module';
import { MspPackagesPageModule } from './modules/msp/packages-page/packages-page.module';
import { PackageBuilderModule } from './modules/package-builder/package-builder.module';
import { PackageSubscriptionModule } from './modules/package-subscription/package-subscription.module';
import { SharedModule } from './modules/shared/shared.module';
import { MonitorInterceptor } from './monitor.interceptor';
import { EnumToStringPipe } from './pipes/enum-to-string.pipe';
import { HumanizeCronPipe } from './pipes/humanize-cron.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { GroupAssignmentDetailsComponent } from './components/group-assignment-details/group-assignment-details.component';
import { LogListComponent } from './components/shared/log-list/log-list.component';
import { PackageLogsComponent } from './components/package-logs/package-logs.component';
import { updateIcon } from '@cds/core/icon/shapes/update';
import { MsiPackageLogsComponent } from './components/msi-package-logs/msi-package-logs.component';
import { PackageCleanupModule } from './modules/package-cleanup/package-cleanup.module';
import { EndpointApplicationsTabComponent } from './components/endpoint-applications-tab/endpoint-applications-tab.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ConfigurePackageLifecycleComponent } from './components/configure-package-lifecycle/configure-package-lifecycle.component';
import { VersionHistoryPageComponent } from './components/version-history-page/version-history-page.component';
import { MspVersionHistoryPageComponent } from './components/msp-version-history-page/msp-version-history-page.component';
import { PackageSuggestionsComponent } from './components/package-suggestions/package-suggestions.component';
import { PackageSuggestionsTabComponent } from './components/package-suggestions-tab/package-suggestions-tab.component';
import { ApplicationsUpdateStatusComponent } from './components/reports/applications-update-status/applications-update-status.component';
import { DeviceUpdateStatusComponent } from './components/reports/device-update-status/device-update-status.component';
import { ApplicationLifecycleTrackingComponent } from './components/reports/application-lifecycle-tracking/application-lifecycle-tracking.component';
import { MspApplicationsUpdateStatusComponent } from './components/reports-msp/msp-applications-update-status/msp-applications-update-status.component';
import { MspDeviceUpdateComplianceComponent } from './components/reports-msp/msp-device-update-compliance/msp-device-update-compliance.component';
import { MspVersionTrackingComponent } from './components/reports-msp/msp-version-tracking/msp-version-tracking.component';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

ClarityIcons.addIcons(
  updateIcon,
  minusCircleIcon,
  successStandardIcon,
  timesCircleIcon,
  infoStandardIcon,
  windowCloseIcon,
  timesIcon,
  undoIcon,
  installIcon,
  uninstallIcon,
  downloadIcon,
  trashIcon,
  errorStandardIcon,
  errorMiniIcon,
  angleIcon,
  cogIcon,
  wrenchIcon,
  refreshIcon,
  floppyIcon,
  redoIcon,
  sunIcon,
  moonIcon,
  clusterIcon,
  fileSettingsIcon,
  circleIcon,
  banIcon,
  lockIcon,
  unlockIcon,
  dragHandleIcon,
  bubbleExclamationIcon,
  noteIcon,
  listIcon,
  exportIcon,
  warningStandardIcon,
  plusCircleIcon,
  uploadIcon,
  folderIcon,
  copyIcon,
  popOutIcon,
  switchIcon,
  gridChartIcon,
  usersIcon,
  applicationIcon,
  computerIcon,
  lineChartIcon,
  applicationsIcon,
  devicesIcon,
  pencilIcon,
  worldIcon,
  recycleIcon,
  backupRestoreIcon,
  processOnVmIcon,
  flagIcon,
  historyIcon,
  asteriskIcon
);

@NgModule({
  declarations: [
    AppComponent,
    ConfigurationComponent,
    BlueprintsComponent,
    AssignmentsComponent,
    StatusComponent,
    BlueprintDetailsComponent,
    AssignmentDetailsComponent,
    EndpointsComponent,
    ActionsComponent,
    DashboardComponent,
    NotifyMeComponent,
    LoginComponent,
    GroupsComponent,
    TagsComponent,
    CronEditComponent,
    NdAddTagsModalComponent,
    TerminalComponent,
    ApplicationsComponent,
    EndpointLogsComponent,
    TabbedTerminalComponent,
    GroupBadgeComponent,
    LifecycleBadgeComponent,
    TruncatePipe,
    PackageLifecycleComponent,
    ExpandablePackageItemComponent,
    PackageVersionCardComponent,
    PackageLifecycleColumnComponent,
    HumanizeCronPipe,
    SchedulePickingTabComponent,
    EnumToStringPipe,
    DownloadMsiInstallerComponent,
    GroupAssignmentDetailsComponent,
    LogListComponent,
    PackageLogsComponent,
    MsiPackageLogsComponent,
    EndpointApplicationsTabComponent,
    ConfigurePackageLifecycleComponent,
    VersionHistoryPageComponent,
    MspVersionHistoryPageComponent,
    PackageSuggestionsComponent,
    PackageSuggestionsTabComponent,
    ApplicationsUpdateStatusComponent,
    DeviceUpdateStatusComponent,
    ApplicationLifecycleTrackingComponent,
    MspApplicationsUpdateStatusComponent,
    MspDeviceUpdateComplianceComponent,
    MspVersionTrackingComponent
  ],
  imports: [
    PackageSubscriptionModule,
    SharedModule,
    PackageBuilderModule,
    MspDashboardModule,
    MspPackagesPageModule,
    CustomersModule,
    CustomerBuilderModule,
    BrowserModule,
    AppRoutingModule,
    ClarityModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ScrollingModule,
    DragDropModule,
    CdsModule,
    PackageCleanupModule,
    DragToSelectModule.forRoot(),
    MatTooltipModule,
    MatPaginatorModule,
    ContextMenuModule.forRoot(),
    MatMenuModule,
    MatAutocompleteModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: '74b18863-387c-44ec-b88d-1632efebbf17',
          authority:
            'https://login.microsoftonline.com/94dc6009-acb7-4644-aa31-423c5eef4a6f',
          redirectUri: environment.api.redirectUrl,
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: isIE,
        },
      }),
      {
        interactionType: InteractionType.Redirect,
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map(),
      }
    ),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MonitorInterceptor,
      multi: true,
    },
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
