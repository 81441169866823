import { Component, OnInit } from '@angular/core';
import {
  CpeInformation,
  CpeServiceService,
} from 'src/app/services/cpe-service.service';

@Component({
  selector: 'app-cpe-page',
  templateUrl: './cpe-page.component.html',
  styleUrls: ['./cpe-page.component.scss'],
})
export class CpePageComponent implements OnInit {
  cpes: CpeInformation[] = [];
  editingCpe?: CpeInformation = undefined;
  isLoading = true;
  isEditing = false;

  constructor(private cpeService: CpeServiceService) {}

  ngOnInit(): void {
    this.fetchCpes();
  }

  fetchCpes(): void {
    this.cpeService.getCpeInformation().subscribe({
      next: (data) => {
        this.cpes = data;
        this.isLoading = false;
      },
    });
  }

  openEditModal(cpe: any): void {
    this.editingCpe = { ...cpe };
    this.isEditing = true;
  }

  saveChangesForCpe(cpe: CpeInformation): void {
    const index = this.cpes.findIndex((c) => c.id == cpe.id);

    this.cpes = [
      ...this.cpes.slice(0, index),
      cpe,
      ...this.cpes.slice(index + 1),
    ];

    this.cpeService.patchCpeString(cpe.id, cpe.cpeString).subscribe();
  }
}
