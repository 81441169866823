<div class="card table-container-card">
    <div class="table-heading" style="border-bottom: none; margin-bottom: 0px !important;">
        <div class="table-title">
            <cds-icon shape="vm" size="md"></cds-icon>
            <h3>Common Platform Enumerations</h3>
        </div>
        <div class="action-group p-3">
        </div>
    </div>
    <clr-datagrid [clrDgLoading]="isLoading" class="h-100">
        <clr-dg-column [clrDgField]="'displayName'">Application Name</clr-dg-column>
        <clr-dg-column [clrDgField]="'vendor'">Vendor</clr-dg-column>
        <clr-dg-column [clrDgField]="'cpeString'">CPE</clr-dg-column>
        <clr-dg-column></clr-dg-column>
        <clr-dg-row #refEl *clrDgItems="let cpe of cpes">
            <clr-dg-cell>{{cpe.displayName}}</clr-dg-cell>
            <clr-dg-cell>{{cpe.vendor}}</clr-dg-cell>
            <clr-dg-cell>{{cpe.cpeString}}</clr-dg-cell>
            <clr-dg-cell>
                <button (click)="openEditModal(cpe)" type="button" class="btn btn-sm btn-icon btn-primary"
                    aria-label="settings">
                    <cds-icon shape="pencil"></cds-icon>
                    Override
                </button>
            </clr-dg-cell>
        </clr-dg-row>
        <clr-dg-footer>
            <clr-dg-pagination #pagination [clrDgPageSize]="100">
                <clr-dg-page-size [clrPageSizeOptions]="[30,50,100]">Packages per page</clr-dg-page-size>
                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} Packages
            </clr-dg-pagination>
        </clr-dg-footer>
    </clr-datagrid>
</div>

<clr-modal #modal [(clrModalOpen)]="isEditing" [clrModalSize]="'md'">
    <div class="modal-title">{{editingCpe?.displayName}}</div>
    <div class="modal-body pb-3">
        <div class="clr-form-control">
            <label for="basic" class="clr-control-label">Common Platform Enumeration</label>
            <div class="clr-control-container">
                <div class="clr-input-wrapper">
                    <input *ngIf="editingCpe" [(ngModel)]="editingCpe.cpeString" type="text" id="basic"
                        placeholder="cpe:3.1:a:vendor:application" class="clr-input" />
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="isEditing = false">Cancel</button>
        <button type="button" class="btn btn-primary"
            (click)="isEditing = false; saveChangesForCpe(editingCpe)">Ok</button>
    </div>
</clr-modal>