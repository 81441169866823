import { Component, OnInit } from '@angular/core';
import { ApplicationVersionTracking, DeviceUpdateStatus, ExtendedReportingService } from 'src/app/services/extended-reporting.service';

@Component({
  selector: 'app-msp-version-tracking',
  templateUrl: './msp-version-tracking.component.html',
  styleUrls: ['./msp-version-tracking.component.scss']
})
export class MspVersionTrackingComponent implements OnInit {
  deviceStatuses: ApplicationVersionTracking[] = [];
  isLoading = true;

  constructor(private extendedReportingService: ExtendedReportingService) {}

  ngOnInit(): void {
    // Fetch device update statuses on component init
    this.extendedReportingService.getMspVersionLifecycleStatus().subscribe(
      (data) => {
        this.isLoading = false;
        this.deviceStatuses = data;
        console.log('Fetched Device Statuses: ', this.deviceStatuses);
      },
      (error) => {
        console.error('Error fetching device statuses: ', error);
      }
    );
  }

  downloadCsv(): void {
    this.extendedReportingService.getMspVersionLifecycleStatusAsCsv();
  }
}
